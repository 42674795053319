<template>
<div class="content-panel">
  <el-row :gutter="10" type="flex" justify="space-between" align="middle" class="breadcrumb-wrap">
    <el-col :span="20">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">{{msg('首页')}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{msg('打印小票')}}</el-breadcrumb-item>
      </el-breadcrumb>
    </el-col>
  </el-row>
  <el-row :gutter="10"  class="query-wrap" :style="{height: isQueryExpend?'auto':'55px'}">
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
      <el-select v-model="query.status" size="small" placeholder="物流状态" class="query-select" clearable>
        <el-option v-for="(status, idx) in dicts.expStatus" :label="status" :value="idx" :key="idx"></el-option>
      </el-select>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
        <el-input  :placeholder="msg('物流信息编号')" v-model="query.expCode" size="small">
          <i slot="prefix" class="el-input__icon el-icon-tickets"></i>
        </el-input>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
        <!-- <el-input  :placeholder="msg('店铺名')" v-model="query.shopName" size="small">
          <i slot="prefix" class="el-input__icon el-icon-tickets"></i>
        </el-input> -->
        <el-select v-model="query.shopId" class="query-select" placeholder="店鋪名" size="small" clearable>
          <el-option v-for="p in shops" :label="p.name" :value="p.shop_id" :key="p.id"></el-option>
        </el-select>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
        <el-input  :placeholder="msg('袋号')" v-model="query.pkgCode" size="small">
          <i slot="prefix" class="el-input__icon el-icon-suitcase"></i>
        </el-input>
    </el-col>  
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
      <el-select v-model="query.packaged" class="query-select" placeholder="是否有包号" size="small" clearable>
        <el-option v-for="p in dicts.packageds" :label="p.label" :value="p.value" :key="p.value"></el-option>
      </el-select>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4" >
      <el-select v-model="query.storePkg" class="query-select" size="small" placeholder="是否需要仓库打包" clearable>
        <el-option v-for="p in dicts.hasStorePkgs1" :label="p.label" :value="p.value" :key="p.value"></el-option>
      </el-select>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4" clearable >
        <el-select v-model="query.hasInnExp" class="query-select" size="small" placeholder="是否有国内快递单号" clearable>
          <el-option v-for="(p, idx) in dicts.hasInnExps" :label="p" :value="idx" :key="idx"></el-option>
        </el-select>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
        <el-input  :placeholder="msg('创建人')" v-model="query.createrName" size="small">
          <i slot="prefix" class="el-input__icon el-icon-user"></i>
        </el-input>
    </el-col>
    
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
       <el-date-picker  v-model="query.Date"  type="daterange"  :range-separator="msg('至')" 
            :start-placeholder="msg('创建开始日期')"  value-format="yyyy-MM-dd"
            :end-placeholder="msg('创建结束日期')"
            size="small"
          >
        </el-date-picker>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
      <el-select v-model="query.allowUploadPage" class="query-select" placeholder="是否允许上传面单" size="small" clearable>
        <el-option v-for="p in dicts.allowUploadPages1" :label="p.label" :value="p.value" :key="p.value"></el-option>
      </el-select>
    </el-col>

    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
        <el-input  :placeholder="msg('报单备注')" v-model="query.remark2" size="small">
          <i slot="prefix" class="el-input__icon el-icon-suitcase"></i>
        </el-input>
    </el-col>  
  </el-row>
 <div class="ctrl-wrap">
    <div >
      <el-select v-model="ptype" v-show="autoPrint"  placeholder="类型" size="small" style="width:135px;" clearable>
        <el-option :label="msg('货单号')" :value="1"></el-option>
        <el-option :label="msg('国内物流单号')" :value="2"></el-option>
        <el-option :label="msg('袋号')" :value="3"></el-option>
      </el-select>
      <el-input style="display:inline-block; width:200px; margin-right:15px;" 
          @keyup.native="inputKeyup"
          :placeholder="msg('请输入')" ref="inputer" v-show="autoPrint" 
          v-model="inputCode" size="small">
        <i slot="prefix" class="el-input__icon el-icon-tickets"></i>
      </el-input>
      <el-checkbox-group v-model="processList" style="display:inline-block;">
        <el-checkbox :label="1" v-if="!autoPrint">{{msg("执行打包")}}</el-checkbox>
        <el-checkbox :label="2" v-if="hasPermission('express:showPrintDiv')">{{msg("执行打印面单")}}</el-checkbox>
        <el-checkbox :label="3">{{msg("执行打印货单")}}</el-checkbox>
        <el-checkbox :label="4">{{msg("不打印拣货单")}}</el-checkbox>
      </el-checkbox-group>
      <el-switch v-model="autoPrint" inactive-text="自动打印"  active-color="#13ce66" inactive-color="#ff4949"
        style="margin-left:15px; margin-right:4px;"
      ></el-switch>
      <el-button type="primary" size="mini" @click="doProcess()"
          v-if="!autoPrint">
        <i class="el-icon-check" style="display:inline-block;margin-right:4px;"></i>
        <transition name="el-fade-in-linear">
          <span v-show="!isMinScreen">{{msg('确定执行操作')}}</span>
        </transition>
      </el-button>
    </div>
  </div>
  <vxe-toolbar id="toolbar12" class="ctrl-wrap" :custom="{storage: true}">
    <template v-slot:buttons>
      <el-button-group>
        <el-button type="primary" size="mini" @click="updatePkg()" v-if="hasPermission('express:updatePkg')">
          <i class="el-icon-edit" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('更新袋号')}}</span>
          </transition>
        </el-button>
        <el-button type="primary" size="mini" @click="importPkg()" v-if="hasPermission('express:importPkg')">
          <i class="el-icon-edit" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('批量导入袋号')}}</span>
          </transition>
        </el-button>
        <el-button type="primary" size="mini" @click="storePkg()" v-if="hasPermission('express:storePkg')">
          <i class="el-icon-edit" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('仓库打包')}}</span>
          </transition>
        </el-button>
        <el-dropdown  @command="clickProcessItem">
          <el-button type="primary" size="mini" icon="el-icon-upload">
            <transition name="el-fade-in-linear">
              <span v-show="!isMinScreen">{{msg('下载驱动')}}</span>
            </transition>
            <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="gprint">{{msg('佳博1324D')}}</el-dropdown-item>
            <el-dropdown-item command="exeprint">{{msg('得力DL-888D')}}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-button type="success" size="mini" @click="batchSend()" v-if="hasPermission('shop:batchSend')">
          <i class="el-icon-s-promotion" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('一键发货')}}</span>
          </transition>
        </el-button>
      </el-button-group>
    </template>
    <template slot="tools">
      <el-button-group>
        <el-button type="success" @click="getExps(true)" size="mini" v-if="hasPermission('express:list')">
          <i class="el-icon-search" style="display:inline-block;margin-right:4px; "></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('搜索')}}</span>
          </transition>
        </el-button>
        <el-button type="success" @click="queryVisiable = true;" size="mini">
          <i class="el-icon-search" style="display:inline-block;margin-right:4px; "></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('批量查询')}}</span>
          </transition>
        </el-button>
        <el-button type="info" size="mini" @click="expendQuery()">
          <i class="el-icon-arrow-down" v-if="!isQueryExpend" style="display:inline-block;margin-right:4px; "></i>
          <i class="el-icon-arrow-up"  v-else style="display:inline-block;margin-right:4px; "></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('高级')}}</span>
          </transition>
        </el-button>
        <el-button type="success" size="mini" @click="$refs.dataTable.exportData({ type: 'xlsx',filename: msg('分包打单'),sheetName: 'Sheet1' })" v-if="hasPermission('express:download')">
          <i class="el-icon-download" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('导出')}}</span>
          </transition>
        </el-button>
      </el-button-group>
    </template>
  </vxe-toolbar>
  <div class="table-wrap">
    <vxe-table style="width: 100%" :height="docHeight" ref="dataTable"
      border="full" size="small" resizable  :data="exps" 
      align="center"
      :scroll-y="{enabled: false}"
      :span-method="mergeRowMethod"
      highlight-hover-row 
    >
      <vxe-table-column type="checkbox" field="id" width="40" :fixed="isMinScreen?null: 'left'"></vxe-table-column>
      <vxe-table-column field="orderCode" :title="msg('基本资料')" min-width="150">
        <template v-slot="{ row }">
          <div>
              <span style="font-weight:bold;font-size:15px;">{{row.orderCode}}</span>
            </div>
            <div>
              <span style="color:#40518c">{{row.shopOwner}}</span>
            </div>
            <div>
              <span>{{row.receiver}}</span>
            </div>
            <div>
              <span>{{row.expCompany}}</span>
            </div>
            <div>
              <span>{{row.created}}</span>
            </div>
            <div>
              <el-link style="margin-right:5px;" :underline="false" type="primary" @click="checkTrack(row)" title="查看物流记录">
                <i class="el-icon-map-location"></i>
              </el-link>
              <el-tag :type="dicts.cssTypes[row.status]" disable-transitions size="medium" style="font-size:15px;">
                {{dicts.expStatus[row.status]}}
              </el-tag>
            </div>
          </template>
      </vxe-table-column>
      <vxe-table-column field="productId" :title="msg('图片')" min-width="120">
        <template v-slot="{ row }">
          <el-image  style="width: 100px; height: 100px" :src="imgView+row.productId" :preview-src-list="[imgView+row.productId]"></el-image>
        </template>
      </vxe-table-column>
      <vxe-table-column field="orderName" :title="msg('商品标题')" min-width="150" 
      >
        <template v-slot="{ row }">
          <el-link :href="linkProduct+'?thumbId='+row.productId" target="_blank">{{row.orderName}}</el-link>
        </template>
      </vxe-table-column>
      <vxe-table-column field="orderSpec" :title="msg('规格')" min-width="120" 
      ></vxe-table-column>
      <vxe-table-column field="sku" :title="msg('SKU')" min-width="120" 
      >
        <template v-slot="{ row }">
          <span style="color:#2baf14;">{{row.sku}}</span>
        </template>
      </vxe-table-column>
      <vxe-table-column field="price" :title="msg('价格')" min-width="120" >
        <template v-slot="{ row }">
              <span style="font-size:15px;">{{row.price?row.price.toFixed(2):0}}</span>
        </template>
      </vxe-table-column>
      <vxe-table-column field="qty" :title="msg('数量')" min-width="120" >
        <template v-slot="{ row }">
              <span style="font-size:20px;color:red;">{{row.qty}}</span>&nbsp;&nbsp;{{'件'}}
        </template>
      </vxe-table-column>
      <vxe-table-column field="remark" :title="msg('买家备注')" min-width="100" 
      >
        <template v-slot="{ row }">
          <span style="font-size:18px;color:red;">{{row.remark}}</span>
        </template>
      </vxe-table-column>
      <vxe-table-column field="note" :title="msg('卖家备注')" min-width="100" 
      >
        <template v-slot="{ row }">
          <span style="font-size:18px;color:red;">{{row.note}}</span>
          <el-link :underline="false" type="primary" @click="updateNote(row)" title="更新卖家备注">
              <i class="el-icon-edit"></i>
          </el-link>
        </template>
      </vxe-table-column>
      <!-- <vxe-table-column field="receiver" :title="msg('收件人')" min-width="100" 
      >
        <template v-slot="{ row }">
          <span>{{row.receiver}}</span>
        </template>
      </vxe-table-column> -->
      <vxe-table-column field="id" :title="msg('添加详情')"  min-width="80" sortable>
        <template v-slot="{ row }">
          <el-link :underline="false" type="primary" @click="AddDetail(row)" title="添加详情">
            <i class="el-icon-circle-plus-outline"></i>
            添加详情
          </el-link>
        </template>
      </vxe-table-column>
      <!-- <vxe-table-column field="receiver" :title="msg('物流记录')" min-width="60" 
      >
        <template v-slot="{ row }">
          <el-link :underline="false" type="primary" @click="checkTrack(row)" title="查看物流记录">
              <i class="el-icon-place"></i>
          </el-link>
        </template>
      </vxe-table-column> -->
      <vxe-table-column field="status" :title="msg('物流状态')" min-width="100" 
        sortable :filters="dicts.expStatus1"
      >
        <template v-slot="{ row }">
          <el-tag :type="dicts.cssTypes[row.status]" disable-transitions size="medium" style="font-size:15px;">
            {{dicts.expStatus[row.status]}}
          </el-tag>
        </template>
      </vxe-table-column>
      <vxe-table-column field="id" :title="msg('操作')"  min-width="80" sortable>
        <template v-slot="{ row }">
          <div><span><el-button type="success" size="mini" @click="printTicketPage(row,2)" v-if="hasPermission('express:printTicketPage1')">{{msg('直發 4 天')}}</el-button></span></div>
          <div style="margin-top:20px;"><span><el-button type="warning" size="mini" @click="printTicketPage(row,3)" v-if="hasPermission('express:printTicketPage2')">{{msg('打印货单')}}</el-button></span></div>
          <div style="margin-top:20px;"><span><el-button type="danger" size="mini" @click="openMatchSearch(row)" v-if="hasPermission('express:printTicketPage2')">{{msg('匹配库存')}}</el-button></span></div>
        </template>
      </vxe-table-column>
    </vxe-table>
    <el-pagination id="xpagination"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :background="true"
      :current-page.sync="currentPage"
      :page-sizes="[10,20 ,50, 100, 200, 500, 1000,2000, 5000,10000,20000,50000]"
      :page-size="query.length"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>

  <!-- <el-dialog :title="msg('打印预览')" :visible.sync="dialogPrintVisiable" :fullscreen="isMinScreen" width="650px"> -->
    <vue-easy-print tableShow ref="easyPrint">
      <template>
        <ticket-tpl :tickets="tickets" :check-codes="checkCodes" :process-list="processList" :printName="printName"></ticket-tpl>
      </template>
    </vue-easy-print>
  <!-- </el-dialog> -->
  
  <el-dialog :title="msg('批量导入袋号')" :visible.sync="importPkgsVisable" :fullscreen="isMinScreen"  width="600px" @opened="hendleOpenedImport()" >
    <pkg-code-import ref="importPkgDialog"/>
  </el-dialog> 

  <el-dialog :title="msg('订单详情')" :visible.sync="orderVisiable" :fullscreen="isMinScreen"  width="800px" >
    <div>
      <el-table :data="orders" style="width: 100%" height="300px" size="mini" ref="orderTable" stripe>
        <el-table-column  prop="id" :label="msg('ID')" min-width="70"></el-table-column>
        <el-table-column  prop="name" :label="msg('商品名')" min-width="150"></el-table-column>
        <el-table-column  prop="spec" :label="msg('规格')" min-width="120"></el-table-column>
        <el-table-column  prop="qty" :label="msg('件数')" min-width="70"></el-table-column>
        <el-table-column  prop="innerExpCode" :label="msg('国内物流快递')" min-width="120"></el-table-column>
        <el-table-column  prop="storeShelfCode" :label="msg('图片')" min-width="70">
          <template slot-scope="scope">
            <el-image style="width: 45px; height: 45px"
              :src="imgView + scope.row.productPicId"  :preview-src-list="[imgView + scope.row.productPicId]">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column  prop="remark" :label="msg('备注')" min-width="120"></el-table-column>
      </el-table>
    </div>
  </el-dialog> 

  <el-dialog :title="msg('批量查询')" :visible.sync="queryVisiable" :fullscreen="isMinScreen"  width="450px" >
    <express-query ref="queryForm" @closeDg="doBatchQuery"/>
  </el-dialog> 

  <el-dialog :title="msg('物流查询')" :close-on-click-modal="false" 
      :visible.sync="traceVisible" width="600px"
      @opened="openTrace"
      :fullscreen="isMinScreen">
          <trace :trackExpId="trackExpId" ref="traceRef"></trace>
  </el-dialog>

  <el-dialog :title="msg('选择打包类型')" :visible.sync="expStoreEdit" :fullscreen="isMinScreen"  width="600px" :append-to-body="true">
      <exp-store-edit :ids="ids" ref="expStore" v-on:closeDg="closeExpStoreDialog"/>
  </el-dialog>

  <el-dialog :title="msg('添加详情')" :visible.sync="addDetailVisible" :fullscreen="isMinScreen"  width="600px" :append-to-body="true" @opened="openedAddDetailType">
      <add-stock-detail-dialog  ref="addDetailRed" v-on:closeDg="closeAddDetailDialog"/>
  </el-dialog> 

  <el-dialog :title="msg('匹配库存')" :visible.sync="searchVisible" :fullscreen="isMinScreen"  width="80%" @opened="openSearch" :append-to-body="true">
      <stock-match :checkExps="checkExps" ref="searchRef" v-on:close-dg="closeSearchDialog"/>
  </el-dialog> 

  <div style="display:inline-block; width:1px; height:1px; overflow:hidden;">
    <audio src="/media/1.wav" controls="controls" preload ref="wav"></audio>
  </div>
</div>
</template> 

<script>
// @ is an alias to /src
import XEUtils from 'xe-utils';
import vueEasyPrint from "vue-easy-print";
import TicketTpl from "./ExpPrintTicketTpl";
import ExpPkgCodeImport from "./ExpPkgCodeImport.vue";
import AddStockDetailDialog from "./AddStockDetailDialog.vue";
import ExpStoreEdit from "./ExpPrintTicketStoreEdit.vue";
import ExpressQuery from "./ExpressQuery.vue";
import Trace from "./Trace.vue";
import StockMatch from "./StockMatch.vue";

export default {
  name: 'ExpPrintTicket',
  components: {
    vueEasyPrint:vueEasyPrint,
    TicketTpl: TicketTpl,
    PkgCodeImport:ExpPkgCodeImport,
    ExpStoreEdit:ExpStoreEdit,
    ExpressQuery: ExpressQuery,
    Trace:Trace,
    StockMatch:StockMatch,
    AddStockDetailDialog:AddStockDetailDialog
  },
  mounted:function(){
    var _this = this;
    var user = JSON.parse(window.localStorage.getItem("user"));
    this.userName = user.username;
    this.getExps();
    this.getShops();
  },
  data(){
    return{
        query:{  //查询条件
          start: 0,
          length: 10,
          pageIndex:1,
          pageTotal: 0,
          expCode: null,
          pkgCode: null,
          packaged: null,
          expCompany: null,
          status: 0,
          createrName: null,
          Date: [], 
          PayDate: [], 
          allowUploadPage:null,
          hasStorePkg:null,
          shopName:null,
          shopId:null,
          remark2:null,
        },

        currentPage: 1,   //分页的当前页
        exps: [],   //表格数据列表
        isQueryExpend: this.$store.state.isQueryExpend,  //查询框是否展开
        total: 0,//总记录数,

        queryVisiable: false, //查询弹窗

        searchVisible: false,

        addDetailVisible:false,
        addStockId:null,
        addStockCode:null,

        importPkgsVisable: false,

        traceVisible:false,
        trackExpId:null,

        orderVisiable: false,
        orders: [],

        expStoreEdit:false,
        ids:[],
        userName:null,
        dialogImportVisible: false,
        dglogTitle:null,
        dialogTableVisible:false,
        currExp:null,
        currId:null,
       
        processList: [], //操作类型
        autoPrint: false,   //是否开启自动打印
        ptype: 1, //编号类型： 1-货单号，2-国内物流编号，3-袋号
        prvePcode: null, //未输入完的编号
        pcode: null, //编号
        
        tickets: null, //要打印的数据
        checkCodes: [],

        hasInput: false, //是否正在输入
        inputTimer: null,

        imgView: this.$kit.api.imgView,
        linkProduct: this.$kit.api.linkProduct,
        shops:[],
        printName:0,
        dbExpress:null,
        checkExps:[],
    } 
  }, 
  computed: {
    isMinScreen: {  //是否小屏幕，浏览器
        get() {
          return this.$store.state.isMinScreen;
        }
    },
    docHeight: {  //表格的高度
        get() {
          var height =  this.$store.state.docHeight;
          return height - 120;
        }
    },
    inputCode: {
      get(){
        return this.pcode;
      },
      set(newValue){
        this.prevPcode = this.pcode;
        this.pcode = newValue;
      }
    },
  },
  methods: {
    getShops(){
      var _this = this;
      this.axios.get(this.$kit.api.getShops)
      .then(function (result) {
        if(result.status){
          _this.shops = result.data;
        }
        
      }).catch(function (error) {
      });
    },
    getExps: function(start){ //获取列表
      var _this = this;

      var param = this.$kit.rebuildQuery(this.query);
      
      if(start){
        param.start = 0;
        this.currentPage = 1;
        this.queryType = null;
        this.queryCodes = null;
      }

      this.axios.post(this.$kit.api.expListForTicket, param)
      .then(function (result) {
        if(result.status){
          // if(_this.exps.length>0)
          //   _this.exps.splice(0, _this.exps.length);
          
          // _this.total = result.data.recordsTotal;
          // var expDatas = result.data.data;

          // _this.query['type'] = null;
          // _this.query['codes'] = null;

          // for(var i=0; i<expDatas.length; i++){
          //   var item = expDatas[i];

          //   _this.exps.push(item);
          // }
          _this.exps = result.data.list;
          _this.total = result.data.total;
        
        }else{
          _this.$message(result.msg);
        }
        
      }).catch(function (error) {
        // console.error(error);
      });
 
    },//end function
    mergeRowMethod ({ row, _rowIndex, column, visibleData }) {
      const fields = ['id','orderCode','remark','note','status']
      const cellValue = XEUtils.get(row, column.property)
      if (cellValue && fields.includes(column.property) && visibleData) {
        const prevRow = visibleData[_rowIndex - 1]
        let nextRow = visibleData[_rowIndex + 1]
        if (prevRow && XEUtils.get(prevRow, column.property) === cellValue) {
          return { rowspan: 0, colspan: 0 }
        } else {
          let countRowspan = 1
          while (nextRow && XEUtils.get(nextRow, column.property) === cellValue) {
            nextRow = visibleData[++countRowspan + _rowIndex]
          }
          if (countRowspan > 1) {
            return { rowspan: countRowspan, colspan: 1 }
          }
        }
      }
    },
    refresh: function(){  //刷新列表
      this.$kit.clearQuery(this.query);
      this.currentPage = 1;
      this.getExps();
    },
    expendQuery: function(){  //展开与收缩查询框
        this.isQueryExpend = !this.isQueryExpend;
    },
    handleSizeChange(val) {  //分页的每页数据变化后的回调事件
      //console.log(`每页 ${val} 条`);
      this.query.length = val;
      this.query.start = 0;
      this.getExps();
    },
    handleCurrentChange(val) {   //分页的当前页改变后的回调事件
      //console.log(`当前页: ${val}`);
      this.query.start = this.query.length * (val -1);
      this.getExps();
    },
    //批量查询
    doBatchQuery(data){
      this.queryVisiable = false;
      if(data){
        this.query['type'] = data.type;
        this.query['codes'] = data.codes;
        this.queryType = data.type;
        this.queryCodes = data.codes;
        this.getExps();
      }else{
        this.queryType = null;
        this.queryCodes = null;
      }
    },

    updatePkg(){
      var _this = this;
      var selectes = _this.$refs.dataTable.getCheckboxRecords();
      if(selectes.length==0){
        _this.$message(_this.msg('请至少选中一条数据进行操作'));
        return;
      }

      //判断打包类型是不是一致
      var firstPkgType = selectes[0].hasStorePkg;
      for(let i=1; i<selectes.length; i++){
        var exp = selectes[i];
        if(exp.hasStorePkg != firstPkgType) {
          this.$message(this.msg('选中的打包类型不一致，请重新选择'));
          return;
        }
      }

      //判断选中的包号是否已发货
      if(this.userName != "admin"){
        var ermsg = "";
        var isReturn = false;
        for(var i=0; i<selectes.length; i++){
            var exp3 = selectes[i];
            if(exp3.status >= 2){
              ermsg += exp3.orderCode+",";
              isReturn = true;
            }
        }
        if(isReturn){
            _this.$message('货单号：'+ermsg+'已发货，不能再修改！');
            return;
        }
      }

      var ids = selectes.map(e => e.id);

      _this.$prompt(_this.msg('请输入袋号'), _this.msg('提示'), {
        confirmButtonText: _this.msg('确定'),
        cancelButtonText: _this.msg('取消'),
        inputPattern: /^[a-zA-Z0-9]{0,20}$/,
        inputErrorMessage: _this.msg('袋号只能是字母和数字，或者字母加数字组合，不能有空格')
      }).then(({ value }) => {
        //判断选中的包号是否已发货
        if(!value){
          //如果未发货，则提醒警告
          this.$confirm(_this.msg("删除包号意味着取消发货，后续不能再进行货态跟踪，是否继续？"), this.msg('提示'), {
                  confirmButtonText: this.msg('确定'),
                  cancelButtonText: this.msg('取消'),
                  type: 'warning'
                }).then(() => {
                  _this.axios.get(_this.$kit.api.expUpdatePkg+"?pkgCode="+(value?value:"")+"&ids="+ids.join(","))
                  .then( (result) => {
                    _this.$message(result.msg);
                    if(result.status){
                      _this.getExps();
                    }
                  }).catch(function (error) {
                    console.error(error);
                  });
                }).catch(() => {
                  this.$message({
                    type: 'info',
                    message: '取消操作'
                  }); 
                  _this.getExps();  
                });
        }else{
          //更新袋号
          _this.axios.get(_this.$kit.api.expUpdatePkg+"?pkgCode="+(value?value:"")+"&ids="+ids.join(","))
            .then( (result) => {
              _this.$message(result.msg);
              if(result.status){
                _this.getExps();
              }
            }).catch(function (error) {
              console.error(error);
            });
        }

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消操作'
        });       
      });

    },
    updateRemark(row){
      //console.log(row, column, cell);
      
      var _this = this;
      var remark = row.remark;
      _this.$prompt(_this.msg('请输入备注'), _this.msg('提示'), {
        confirmButtonText: _this.msg('确定'),
        cancelButtonText: _this.msg('取消'),
        inputValue: remark?remark:"",
      }).then(({ value }) => {
        _this.axios.post(_this.$kit.api.expUpdateRemark, {id: row.id, remark: value})
          .then( (result) => {
            _this.$message(result.msg);
            if(result.status){
              _this.getExps();
            }
          }).catch(function (error) {
            console.error(error);
          });
      }).catch(() => {
        _this.$message({
          type: 'info',
          message: _this.msg('取消更新')
        });       
      });
    },
    AddDetail(row){
      this.addStockId = row.id;
      this.addStockCode = row.orderCode;
      this.addDetailVisible = true;
    },
    openedAddDetailType(){
      this.$nextTick(() => {
                this.$refs.addDetailRed.init(this.addStockCode,this.addStockId);
            });
    },
    closeAddDetailDialog(type){
      if(type){
        this.getExps();
      }
      this.addDetailVisible = false;
    },
    importPkg(){
      this.importPkgsVisable = true;
    },
    hendleOpenImport(){
      this.$nextTick(() => {
        this.$refs.expPageImport.initForm();
      });
      
    },
    hendleOpenedImport(){
      this.$nextTick(() => {
        this.$refs.importPkgDialog.initForm();
      });
    },
    getOrders(exp){
      this.axios.get(this.$kit.api.orderListByExp+"?id="+exp.id)
        .then( (result) => {
          if(result.status){
            this.orders = result.data;
            this.orderVisiable = true;
          }else{
            this.$message(result.msg);
          }
        }).catch(function (error) {
          console.error(error);
        });
       
    },
    storePkg(){
      this.ids = [];
      var selectes = this.$refs.dataTable.getCheckboxRecords();
      if(selectes.length==0){
        this.$message(this.msg('请选中一条数据进行操作'));
        return;
      }
      
      for(var i=0;i<selectes.length;i++){
        if(this.userName != 'admin'){
          if(selectes[i].hasStorePkg && selectes[i].hasStorePkg != 1){
            this.$message(this.msg('选择的订单包含非客包数据'));
            return;
          }
        }
      }

      var ids = selectes.map( o => o.id);
      
      this.ids = ids;
      this.expStoreEdit = true;
    },
    closeExpStoreDialog(type){  //编辑弹窗关闭后的回调事件
      if(type){
        this.getExps();
      }
      this.expStoreEdit = false;
    },
    closeSearchDialog(){
      this.searchVisible = false;
      this.getExps();
    },
    closeEditDialog(type){  //编辑弹窗关闭后的回调事件
      if(type){
        this.getExps();
      }
      this.dialogTableVisible = false;
    },

    //执行一系列操作： 1-打包，2-打面单，3-打货单，4-不打印找货单
    doProcess(){
      //判断是不是有选择操作
      if(this.processList.length==0){
        this.$message(this.msg('请选择要执行的操作'));
        return;
      }

      if(this.processList.length==1 && this.processList.indexOf(4)>-1){
        this.$message(this.msg('请选择要执行的操作'));
        return;
      }

      var selectes = this.$refs.dataTable.getCheckboxRecords();
      if(selectes.length==0){
        this.$message(this.msg('请选中一条数据进行操作'));
        return;
      }

      //查询当前用户的货单设置，是否需要打印品名
      this.axios.get(this.$kit.api.getUserSet)
        .then( (result) => {
          if(result.status){
            this.printName = result.data;
          }else{
            this.$message(result.msg);
          }
        }).catch(function (error) {
          console.error(error);
        });

      var ids = selectes.map(e => e.id);

      if(this.processList.indexOf(1)>-1){
        var _this = this;
        //判断打包类型是否一致
        var firstPkgType = selectes[0].hasStorePkg;
        for(var i=1; i<selectes.length; i++){
          var exp = selectes[i];
          if(exp.hasStorePkg != firstPkgType) {
            this.$message(this.msg('选中的打包类型不一致，请重新选择'));
            return;
          }
        }

        var packageds = selectes.filter(e => e.pkgId).map(e => e.orderCode);
        if(packageds && packageds.length>0){
          this.$confirm(this.fmt('单号：%s已经打包，是否重新打包？', [packageds.join(",")]), this.msg('提示'), {  
            confirmButtonText: this.msg('确定'),
            cancelButtonText: this.msg('取消'),
            type: 'warning'
          }).then(() => {
            this.inputPkgCode(ids);
          }).catch(() => {
          });
        }else{
          this.inputPkgCode(ids);
        }
        
      }else{
        this.sendProcess(ids, "", 0);
      }
    },

    inputPkgCode(ids){
      var _this = this;
      //输入包号
      _this.$prompt(_this.msg('请输入袋号'), _this.msg('提示'), 
        {confirmButtonText: _this.msg('确定'), cancelButtonText: _this.msg('取消'),
          inputPattern: /^[a-zA-Z0-9]{4,20}$/,
          inputErrorMessage: _this.msg('袋号只能是字母和数字，或者字母加数字组合，不能有空格，长度只能是：4-20')
        }).then(({ value }) => {
            _this.sendProcess(ids, value, 0);
        }).catch(() => {
          _this.$message({type: 'info', message: '取消操作'});       
      });
    },
    openMatchSearch(row){

      this.dbExpress = row;
      this.searchVisible = true;
      this.checkExps.splice(0,this.checkExps.length);
      this.exps.forEach(e => {
        if(e.orderCode == row.orderCode){
          this.checkExps.push(e);
        }
      })
    },
    printTicketPage(row,type){
      let ids = [row.id];
      this.pcode = row.orderCode;
      this.processList = [type];
      this.sendProcess(ids,null,0);
    },
    //将操作发送到后台
    sendProcess(ids, pkgCode, flag){
      var _this = this;
      this.axios.get(this.$kit.api.expPrintPkg+"?flag="+flag+"&pkgCode="+pkgCode+"&ids="+ids.join(",")+"&ps="+this.processList.join(","))
        .then( (result) => {
          this.$message(result.msg);
          if(result.status){
              // if(this.processList.indexOf(1)>-1){
              //   this.getExps();
              // }
              this.getExps();
              this.tickets = result.data;

              if(this.autoPrint){
                this.$refs.inputer.focus();
                this.pcode = null;
                this.prevPcode = null;
              }

              if(this.processList.indexOf(2) > -1 || this.processList.indexOf(3)>-1){
                if(result.msg == '面单不存在'){
                  this.$refs.wav.play();
                  this.$message(result.msg);
                }else{
                  this.doPrintAll();
                }
              }

          }else if(result.code == "-3"){ //说明存在： 存在还有的货没有到，则提示是否继续打包
              this.$confirm(result.msg, this.msg('提示'), {  
                confirmButtonText: this.msg('确定'),
                cancelButtonText: this.msg('取消'),
                type: 'warning'
              }).then(() => {
                _this.sendProcess(ids, pkgCode, 1);
              }).catch(() => {
              });
            }else{
              this.$message(result.msg);
            }
        }).catch(function (error) {
          console.error(error);
        });
    },

    //正在输入数据单号
    inputKeyup(){
      
      if(this.processList.indexOf(2) > -1 || this.processList.indexOf(3)>-1){
        var _this = this;
        //debugger;
        if(this.hasInput == false){
          this.hasInput = true;
          this.inputTimer = setInterval(()=>{
            if(_this.hasInput && _this.pcode){
              if(_this.prevPcode == _this.pcode){
                clearInterval(_this.inputTimer);
                _this.inputTimer = null;
                _this.hasInput = false;
                _this.getPrintData();
              }else{
                _this.prevPcode = _this.pcode;
              }
            }
            
          }, 1000);
        }
      }
    },
    //获取打印数据
    getPrintData(){
      this.axios.get(this.$kit.api.expPrintGet+"?type="+this.ptype+"&code="+this.pcode+"&ps="+this.processList.join(","))
        .then( (result) => {
          this.$message(result.msg);
          if(result.status){
            this.tickets = result.data;
            this.checkCodes = [];
            result.data.forEach(e => {
              if(e.checkCodes){
                this.checkCodes.push(e.checkCodes);
              }
            });

            if(this.autoPrint){
              this.$refs.inputer.focus();
              this.pcode = null;
              this.prevPcode = null;
            }

            if(result.msg == '面单不存在'){
              this.$refs.wav.play();
              this.$message(result.msg);
            }else{
                this.doPrintAll();
            }

          }else{
            if(this.autoPrint){
              this.$refs.inputer.focus();
              this.pcode = null;
              this.prevPcode = null;
            }

            this.$refs.wav.play();

          }
        }).catch(function (error) {
          console.error(error);
        });
    },

    //执行打印操作
    doPrintAll(){
      setTimeout(()=>{
        this.$refs.easyPrint.print();
      }, 500);
    },

  clickProcessItem(command){ 

      if(command=='gprint'){
        window.location.href= this.$kit.api.gprintDownload;
      }else if(command == 'exeprint'){
        window.location.href= this.$kit.api.exeDownload;  
      }

    },
    batchSend(){
      var selectes = this.$refs.dataTable.getCheckboxRecords();
      if(selectes.length==0){
        this.$message(this.msg('请选中一条数据进行操作'));
        return;
      }
      var _this = this;
      this.$confirm(_this.msg('确定要一键发货吗?'), _this.msg('提示'), {
          confirmButtonText: _this.msg('确定'),
          cancelButtonText: _this.msg('取消'),
          type: 'warning'
        }).then(() => {
          _this.axios.get(_this.$kit.api.batchSend+"?ids="+selectes.map(e => e.id).join(",")).then((result) => {
            if(result.status){ 
                _this.getExps(); 
            }
            _this.$message(result.data);
          }).catch(function (error) {
              console.error(error);
          });
        });
    },
    updateNote(row){
      var _this = this;
      this.$prompt('请输入卖家备注', this.msg('提示'), {
            closeOnClickModal:false,
            confirmButtonText: this.msg('确定'),
            cancelButtonText: this.msg('取消'),
        }).then(({ value }) => {
            _this.$axios.post(_this.$kit.api.updateNote
                +"?id="+row.id
                +"&note="+(value?value:"")
            ).then((result) => {
                if(result.status){
                    _this.getExps();
                }
                _this.$message(result.msg);
            });
        }).catch(() => {
            _this.$message({
                type: 'info',
                message: '取消输入'
            });
        });
    } , 
    openTrace(){
      this.$nextTick(() => {
          this.$refs.traceRef.showTracks();
      });
    },
    openSearch(){
      this.$nextTick(() => {
          this.$refs.searchRef.init();
      });
    },
    checkTrack(row){
      this.trackExpId = row.id;
      this.traceVisible = true;
    },
  }
}
</script>

<style class="scss" scoped>


</style>
