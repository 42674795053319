<!-- 新增stock详情 -->
<template>
    <el-form ref="form" :model="stockDetail" label-width="100px" style="margin:0 20px">
        <el-row>
            <el-col :xs="24" :sm="24">
                <el-form-item :label="msg('商品标题')">
                    <el-input v-model.trim="stockDetail.name" ></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :xs="24" :sm="24">
                <el-form-item :label="msg('商品规格')">
                    <el-input v-model.trim="stockDetail.spec" ></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :xs="24" :sm="12">
                <el-form-item :label="msg('数量')">
                    <el-input-number v-model.number="stockDetail.qty" :min="1"></el-input-number>
                </el-form-item>
            </el-col>
        </el-row>
        <!-- <div style="text-align:center; margin-top: 15px;">
            <el-tag type="danger">添加详情不会更新货态，如有需要请自行检查货态是否正确；</el-tag>
        </div> -->
        <div style="text-align:center; margin-top: 15px;">
            <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
            <el-button size="mini" v-on:click="$emit('closeDg')">{{msg('取消')}}</el-button>
        </div>
    </el-form>
</template>

<script>
export default {
    name: "AddStockDetailDialog",
    data(){
        return {
            visible: false,
            stockDetail: {
                id:null,
                name:null,
                spec:null,
                qty:1,
                code:null,
                expId:0,
            }
        }
    },
    computed: {
        isMinScreen: {  //是否小屏幕，浏览器
            get() {
                var is = this.$store.state.isMinScreen;
                return is;
            }
        }
    },
    methods: {
        init(code,stockId){
            this.stockDetail.expId = stockId;
            this.stockDetail.code = code;
        },
        onSubmit(){
            var _this = this;
            // this.stockDetail.productPrice = this.stockDetail.productPrice*100;
            this.$axios.post(
                this.$kit.api.createForTicket, 
                this.stockDetail
            ).then(function(result){
                if(result.status){
                    _this.$message(result.msg || _this.msg("添加成功"));
                    _this.$emit("closeDg", true);
                }else{
                    _this.$message(result.msg || _this.msg("添加失败"));
                }
            });
        },
    }
}
</script>