<!--匹配库存--搜索库存-->
<template>
    <div style="margin-top: -35px;">
        <div style="padding: 15px;">
            <div style="font-weight:bold;font-size:16px;">{{msg('虾皮订单信息')}}：</div>
            <div style="display:flex; flex-direction: column;">
                <span><span style="color: red; font-size:14px;font-weight:bold;">{{msg('标题')}}：</span><span style="font-size:12px;">{{express.orderName}}</span></span>
                <span><span style="color: red;font-size:14px;font-weight:bold;">{{msg('规格')}}：</span><span style="font-size:12px;">{{express.orderSpec}}</span></span>
                <span><span style="color: red;font-size:14px;font-weight:bold;">{{msg('SKU')}}：</span><span style="font-size:12px;">{{express.sku}}</span></span>
                <span><span style="color: red;font-size:14px;font-weight:bold;">{{msg('件数')}}：</span><span style="font-size:12px;">{{express.qty}}</span></span>
            </div>
        </div>
        <el-row>
            <el-col :span="12">
               <div style="display:flex;  align-items:center;">
                    <div style="min-width:80px; text-align:right;">{{msg('库存单号')}}：</div>
                    <el-input v-model="query.code" size="mini"></el-input>
                </div>
            </el-col>
            <el-col :span="12">
                <div style="display:flex; align-items:center;">
                    <div style="min-width:80px; text-align:right;">{{msg('品名')}}：</div>
                    <el-input v-model="query.name" size="mini"></el-input>
                </div>
            </el-col>
        </el-row>
        <el-row style="margin-bottom:10px; margin-top:10px;">
            <el-col :span="12">
                <div style="display:flex; align-items:center;">
                    <div style="min-width:80px; text-align:right;">{{msg('貨物代號')}}：</div>
                    <el-input v-model="query.title" size="mini"></el-input>
                </div>
            </el-col>
            <el-col :span="12">
               <div style="display:flex;  align-items:center;">
                    <div style="min-width:80px; text-align:right;">{{msg('规格')}}：</div>
                    <el-input v-model="query.spec" size="mini"></el-input>
                </div>
            </el-col>
        </el-row>
        <div style="margin: 15px 40px 15px 0px; text-align:left;">
            <el-button type="primary" size="mini" icon="el-icon-search" @click="searchStock">{{msg('搜索')}}</el-button>
            <el-button type="success" style="margin-right:20px;" @click="enterCheck" size="mini" icon="el-icon-check">{{msg('确定选择')}}</el-button>
        </div>
        <div>
            <vxe-table border resizable 
                    ref="dataTable"
                    size="mini" show-header-overflow
                    highlight-hover-row max-height="500"
                    :sort-config="{trigger: 'cell'}"
                    :data="datas">
                <vxe-table-column type="checkbox" field="id"  min-width="50"></vxe-table-column>
                <vxe-table-column :title="msg('库存单号')" show-overflow field="code" min-width="150"></vxe-table-column>
                <vxe-table-column :title="msg('入仓时间')" show-overflow field="created" min-width="150"></vxe-table-column>
                <vxe-table-column :title="msg('可用数量')" field="actualQty" width="150"></vxe-table-column>
                <vxe-table-column :title="msg('品名')" show-overflow field="productName" min-width="150">
                    <template v-slot="{ row }">
                        <div>{{row.productName}}</div>
                    </template>
                </vxe-table-column>
                <vxe-table-column :title="msg('貨物代號')" show-overflow field="productTitle" min-width="200">
                    <template v-slot="{ row }">
                        <div>{{row.productTitle}}</div>
                    </template>
                </vxe-table-column>
                <vxe-table-column :title="msg('规格')" show-overflow field="productSpec" min-width="150">
                    <template v-slot="{ row }">
                        <div>{{row.productSpec}}</div>
                    </template>
                </vxe-table-column>
                <vxe-table-column :title="msg('架位号')" show-overflow field="shelfCode" width="150"></vxe-table-column>
                <vxe-table-column :title="msg('归属人')" show-overflow field="ownerName" width="150"></vxe-table-column>
            </vxe-table>
        </div>
    </div>

</template>

<script>
export default {
    name: "StockMatchSearch",
    created(){
    },
    data(){
        return{
            query:{
                code: null,
                name: null,
                spec: null,
                title:null,
            },
            datas: [],
        }
    },
    props:{
        express:Object,
    },
    methods:{
        init(){
            this.query.code = null;
            this.query.name = null;
            this.query.title = null;
            this.query.spec = null;
            this.datas = [];
        },

        //搜索库存
        searchStock(){
            let param = {
                code: this.query.code,
                name: this.query.name,
                title: this.query.title,
                spec: this.query.spec,
            }
            this.$axios.post(this.$kit.api.expStoreSearch, param)
                .then( (result) => {
                    if(result.status){
                        let data = result.data.list;
                        this.datas = data;
                        if(data.length == 0){
                            this.$message("無可用庫存"); 
                        }
                    }else{
                        this.$message(result.msg); 
                    }
                });
        },
        enterCheck(){
            
            //检查库存，如果库存是0,则不与匹配
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length == 0){
                this.$message(this.msg("请选择库存"));
                return;
            }
            if(selectes.length > 1){
                this.$message(this.msg("只能选择一条数据"));
                return;
            }

            let select = selectes[0];

            this.$emit("close-dg", select,this.express.orderId);
        },

        

    }
}
</script>

<style lang="scss" scoped>
    .el-dialog__body {
    padding: 0px 20px;}
    .resend-box h2{padding-left: 15px; font-size:20px; color: rgba(100,100,100,0.9)}
    .resend-box h3{padding-left: 15px; font-size:16px; color: rgba(100,100,100,0.9)}
    .mytable-style{
        border: 1px solid rgba(0,0,0,0.7) !important;
    }
</style>