<!-- 打印面单，货单-->
<template>
<div class="content-panel">
  <div class="panel-wrap" >
    <div class="print" v-for="(ticket, idx) in tickets" :key="idx">
      <div v-if="processList.indexOf(2)>-1" >
        <img v-if="ticket.page" :src="'data:image/png;base64,'+ticket.page" :style="getStyle(ticket)" />
        <div v-else style="text-align:center; margin-top:5px; font-size:24px;">{{msg("面单不存在")}}<br/>{{ticket.orderCode}}</div>
      </div>
      <div v-if="ticket.orders.length>0" style="page-break-before: always; ">
      </div> 
      <div v-if="(processList.indexOf(3)>-1 && ticket.orders) ||  (processList.indexOf(2)>-1 && ticket.orders.length>1)" 
          class="print-body" >
        <!-- <div style="position:relative; margin:10px auto 0px auto;">
          <div style="text-align:center;">
            <div style="margin-left:-40px;font-size:20px; ">{{ticket.ticketTag}}</div>
            <div><img :src="'data:image/png;base64,'+ticket.qrCode" style="margin-left:-35px;"/></div>
            <div style="margin-left:-30px;margin-top: -20px;font-size:15px; ">{{ticket.orderCode}}</div>
          </div>
        </div>
        <div class="next-page a1"></div> -->
        <div style="position:relative; margin:10px auto 0px auto;">
          <div style="text-align:center;">
            <div><img :src="'data:image/png;base64,'+ticket.lineCodeAsBase64" style="margin-left:-50px;"/></div>
            <div style="margin-left:-30px; font-size:14px; ">{{ticket.orderCode}}</div>
            <div style="position:absolute;right:2px; top:0px;"><img :src="'data:image/png;base64,'+ticket.qrCode"/></div>
          </div>
          <span style="position:absolute; left:25px; top:50px; font-size:15px; font-weight:bold;">{{ticket.expCompany}}</span>
        </div>
        <div class="br" />
        <h1>{{ticket.ticketTag?ticket.ticketTag:'INVOICE'}}</h1>
        <!-- <div class="line">
          <div class="line-block">
            <span class="cell">單&nbsp;&nbsp;號：</span>
            <span class="txt">{{ticket.orderCode}}</span>
          </div>
          <div class="line-block">
            <span class="cell">寄件人：</span>
            <span class="txt">{{ticket.sender}}</span>
          </div>
        </div> -->

        <!-- <div class="line"> 
          <div class="line-block" v-if="processList.indexOf(4)<0">
            <span class="cell">收件人：</span> 
            <span class="txt">{{ticket.receiver}}</span>
          </div>
          <div class="line-block" v-if="processList.indexOf(4)<0">
            <span class="cell">電&nbsp;&nbsp;話：</span>
            <span class="txt">{{ticket.receiverTel}}</span>
          </div>
        </div> -->

        <!-- <div class="line-row">
          <span style="font-size:12px;margin-left:6px;">卖家备注：</span>
          <span style="font-size:10px">{{ticket.note}}</span>
        </div>

        <div class="xt-wrap invoices-wrap">
          <table class="xtable invoice-table">
            <thead>
              <tr>
                <th v-if="processList.indexOf(4)<0">品名</th>
                <th>件數</th>
                <th>單價（NTD）</th>
                <th>金額（NTD）</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td v-if="processList.indexOf(4)<0"><span>{{printName == 1 ? ticket.expName : ""}}</span></td>
                <td>{{ticket.qty}}</td>
                <td>{{ticket.price}}</td>
                <td>{{ticket.amount}}</td>
              </tr>
            </tbody>
          </table>
        </div> -->
        <!--end invoices-->

        <div class="br" />

        <div class="xt-wrap orders-wrap" v-if="processList.indexOf(4)<0">
          <table class="xtable order-table">
            <thead>
              <tr>
                <th >快递单号</th>
                <th >货架号</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(order, index) in ticket.orders" >
              <tr :key="index+''+2">
                <td ><span>{{order.code}}</span></td>
                <td ><span>{{order.storeShelfCode}}</span></td>
              </tr>
              <tr :key="index">
                <td colspan="3"><span>商品名：{{printName == 1 ? order.name : ""}}</span></td>
              </tr>
              <tr :key="index+''+1">
                <td colspan="3" style="text-align:left; border-bottom:3px solid #666;"><span>规格：{{order.spec}}</span></td>
              </tr>
              <tr :key="index+''+3">
                <td colspan="3" style="text-align:left; border-bottom:3px solid #666;"><span>SKU：{{order.sku}}  件数：{{order.qty}}</span></td>
              </tr>
              </template>
            </tbody>
          </table>
        </div>
        <div style="margin: 5px 10px; font-size:13px; display:flex; justify-content: space-between;">
          <span>{{ticket.pkgCode}} / {{ticket.ticketTotal}}</span>
          <span class="date">{{ticket.created}}</span>
        </div>
        <div v-if="ticket.remark" style="text-align:left;font-size:13px; margin: 0px 10px 10px 5px;">{{ticket.remark}}</div>
        <div v-if="ticket.remark2" style="text-align:left;font-size:13px; margin: 0px 10px 10px 5px;">{{ticket.remark2}}</div>
      </div>
      <div v-if="(tickets.length > 1 && idx < tickets.length - 1) || checkCodes.length>0" style="page-break-before: always; margin-top:10px; margin-bottom:10px;"></div>
      <div v-if="checkCodes && checkCodes.length>0">
        <div style="margin-top:10px; margin-bottom:10px; " v-for="code in checkCodes" :key="code">{{code}}<span style="display:inline-block; margin-left:20px;">需验货</span></div>
      </div>
    </div><!--end print-->
  </div>
</div>
</template> 

<script>
// @ is an alias to /src

export default {
  name: 'ExpPrintTicketTpl',
  components: {

  },
  mounted:function(){
  },
  data(){
    return{
      code: null,
    } 
  }, 
  props:{
      tickets:Array,
      processList: Array,
      checkCodes:Array,
      printName:Number,
  },
  methods: {
    getStyle(dto){
      if(dto.newPage){
        let style = {width: "400px"}
        if(dto.expCompany==='7-11'){
          style = {width: "360px"};
        }else if(dto.expCompany === '全家'){
          if(dto.platform == 1){
            style={width: '390px'}
          }else{
            style={width: '380px'}
          }
        }else if(dto.expCompany=='莱尔富'){
          if(dto.platform == 2){
            style={width: '380px'}
          }
        }
        return style;
      }else{
        let style = {};
        if(dto.expCompany == '7-11'){
            style= {
              width: "340px",
              maxHeight: "600px"
            }
        }else if(dto.expCompany == 'OK'){
            style={
              height:"560px"
            }
        }else if(dto.expCompany == '全家'){
            style={
              height: "600px"
            }
        }else if(dto.expCompany != "7-11"){
          style={
            width: "400px",
            maxHeight: "600px"
          }
        }
        return style;
      }

    },
  }
}
</script>

<style scoped>

.panel-wrap{text-align:center; width:100%; background: white;}
.print{margin: 0px auto; text-align:center; }
.print-body{border: 1px solid #999; width:360px; margin: 0px auto;}
.next-page {
  page-break-before: always;
}
.print-body h1{font-size:20px; font-weight: bold; margin: 0px 0px 10px 0px;}
.print-body .line{display:flex; justify-content: space-between; padding: 0 10px; font-size:14px;}
.print-body .line-block{display:flex;  align-items: flex-end; }
.print-body .cell{width:60px; text-align:right;}
.print-body .txt{font-size:12px; width:110px; text-align:left; overflow:hidden; white-space:nowrap; margin-top:3px;}

.print-body .line-row{text-align:left; display: flex;}
.print-body .line-row .cell{display:inline-block; width:70px;}
.print-body .line-row .txt{overflow:hidden; word-wrap: break-word; width: calc(100% - 70px);}

.xtable{width:100%;}
.xtable th{font-size:12px; text-align:center;   
}
.xtable thead th{border-right: 1px solid #8a8a8a; text-align:center;}
.xt-wrap{padding:5px;}
.xtable{border-top: 1px solid #8a8a8a; border-left: 1px solid #8a8a8a; 
  border-collapse:collapse; border-spacing: 0px; 
}
.xtable tbody td{
  border-top: 1px solid #8a8a8a; 
  border-bottom: 1px solid #8a8a8a;
  border-right: 1px solid #8a8a8a;
  font-size:12px;
}

.print-body .date{margin-left:40px; font-size:12px; display:inline-block;}

.invoice-table tbody td:nth-child(1){width:100px; white-space: nowrap; overflow:hidden;}
.invoice-table tbody td:nth-child(2){width:35px;}
.invoice-table tbody td:nth-child(3){width:80px; }


.order-table tbody td:nth-child(2){width:45px; white-space: nowrap;}

.invoice-table tbody td:nth-child(1) span{display:inline-block;width:100px; white-space: nowrap; overflow:hidden;}
 
.order-table tbody td:nth-child(1) span{display:inline-block;word-break:break-all; text-align:left; overflow:hidden; } 
.order-table tbody td:nth-child(2) span{display:inline-block;width:100px; white-space: nowrap; overflow:hidden;}
.order-table tbody td:nth-child(3){width:40px; }
.order-table tbody td:nth-child(4){width: 50px; }

.print-body .br{border-bottom: 2px black dashed; margin-bottom: 10px; margin-top:10px;}

</style>
