<template>
<el-form ref="form" label-width="80px">
    <el-row>
        <el-col :xs="24" :sm="20">
            <el-form-item :label="msg('打包类型')">
                <el-select v-model="storePkg" class="query-select" size="small" placeholder="请选择打包类型" clearable>
                    <el-option v-for="p in dicts.expPkgTypes1" :key="p.value" :label="p.label" :value="p.value"></el-option>
                </el-select>
            </el-form-item>
        </el-col>
    </el-row>
  
    <div style="text-align:center;">
        <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
        <el-button  size="mini" v-on:click="$emit('closeDg')">{{msg('取消')}}</el-button>
    </div>
</el-form> 
</template>

<script>
export default {
  name: 'ExpPkgEdit',
  data(){
    return{
       storePkg:null,
       userName:null,
    }
    
  }, 
  computed:{
      mode: {
          get(){
              return this.id==null;
          }
      }
  },
  props:{
      ids:Array,
  },
  mounted: function(){
        var _this = this;
        var user = JSON.parse(window.localStorage.getItem("user"));
        this.userName = user.username;
  },
  methods:{
    onSubmit(){

        console.log(this.storePkg);
        if(this.userName != 'admin'){
            if(this.storePkg < 2 || this.storePkg > 3){
                this.$message(this.msg('非法操作！！'));
                return;
            }
        }
        var _this = this;

        if(this.storePkg == 2){
            this.$confirm(this.msg('仓库打包会收取一定的打包人工费用，请确认是否继续让仓库打包?'), this.msg('提示'), {
                confirmButtonText: this.msg('确定'),
                cancelButtonText: this.msg('取消'),
                type: 'warning'
            }).then(() => {
               this.doStorePkg();
            }).catch(() => {
                _this.$message({
                type: 'info',
                message: _this.msg('取消操作')
                });       
            });
        }else{
           this.doStorePkg();
        }
    },
    doStorePkg(){
         this.$axios.post(this.$kit.api.updateStorePkg+"?storePkg="+this.storePkg, this.ids)
            .then((result) =>{
                this.$message(result.msg);
                if(result.status){
                    this.$emit("closeDg", true);
                }
            });
    }
  }
}
</script>

<style class="scss" scoped>


</style>
