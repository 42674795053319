<!--匹配库存-->
<template>
    <div style="margin-top: -35px;">
        <div style="padding: 15px;">
            <div style="font-weight:bold;font-size:16px;">{{msg('虾皮单号：')+checkExps[0].orderCode}}</div>
            <div style="font-weight:bold;font-size:16px;">
                <el-tag type="danger">请手动选择匹配数量，如不选择数量，则默认按订单数量进行匹配</el-tag>
            </div>
        </div>
        <div style="margin: 15px 40px 15px 0px; text-align:left;">
            <el-button type="success" style="margin-left:20px;" @click="submitCheck" size="mini" icon="el-icon-check">{{msg('提交匹配结果')}}</el-button>
        </div>
        <div>
            <vxe-table border resizable 
                    ref="dataTable"
                    size="mini" show-header-overflow
                    highlight-hover-row height="400"
                    :sort-config="{trigger: 'cell'}"
                    :data="checkExps">
                <vxe-table-column type="seq" min-width="20"></vxe-table-column>
                <vxe-table-column :title="msg('订单标题')" show-overflow field="orderName" min-width="150">
                    <template v-slot="{ row }">
                        <div>{{row.orderName}}</div>
                    </template>
                </vxe-table-column>
                <vxe-table-column :title="msg('订单规格')" show-overflow field="orderSpec" min-width="100">
                    <template v-slot="{ row }">
                        <div>{{row.orderSpec}}</div>
                    </template>
                </vxe-table-column>
                <vxe-table-column :title="msg('SKU')" show-overflow field="sku" min-width="100">
                    <template v-slot="{ row }">
                        <div>{{row.sku}}</div>
                    </template>
                </vxe-table-column>
                <vxe-table-column :title="msg('订单数量')" field="qty" min-width="60"></vxe-table-column>
                <vxe-table-column :title="msg('选择匹配数量')" field="resendQty" min-width="120">
                    <template v-slot="{ row }">
                        <el-input-number v-model="row.resendQty" :min="1" :step="1" size="mini"></el-input-number>
                    </template>
                </vxe-table-column>
                <vxe-table-column :title="msg('库存单号')" field="expStoreCode" min-width="80"></vxe-table-column>
                <vxe-table-column :title="msg('库存貨物代號')" field="expStoreTitle" min-width="80"></vxe-table-column>
                <vxe-table-column :title="msg('库存规格')" field="expStoreSepc" min-width="80"></vxe-table-column>
                <vxe-table-column :title="msg('库存数量')" field="expStoreQty" min-width="80"></vxe-table-column>
                <vxe-table-column field="id" :title="msg('操作')"  min-width="120" sortable>
                    <template v-slot="{ row }">
                    <div><span><el-button type="success" size="mini" @click="openMatchSearch(row)" v-if="hasPermission('express:printTicketPage2')">{{msg('选择库存')}}</el-button></span></div>
                    </template>
                </vxe-table-column>
            </vxe-table>
        </div>

        <el-dialog :title="msg('选择库存')" :visible.sync="matchSearchVisible" :fullscreen="isMinScreen"  width="70%" @opened="openSearch" :append-to-body="true">
            <stock-match-search :express="express" ref="searchRef" v-on:close-dg="closeSearchDialog"/>
        </el-dialog> 

    </div>

</template>

<script>
import XEUtils from 'xe-utils';
import StockMatchSearch from "./StockMatchSearch.vue";
export default {
    name: "StockMatch",
    components: {
        StockMatchSearch:StockMatchSearch
    },
    created(){
    },
    data(){
        return{
            query:{
                code: null,
                name: null,
                spec: null,
            },
            datas: [],
            express:null,
            matchSearchVisible:false,
        }
    },
    props:{
        checkExps:Array,
    },
    computed: {
        isMinScreen: {  //是否小屏幕，浏览器
            get() {
            return this.$store.state.isMinScreen;
            }
        },
    },
    methods:{
        init(){
            this.query.code = null;
            this.query.name = null;
            this.query.spec = null;
            this.datas = [];
            this.checkExps.forEach(e=>{
                e['resendQty'] = e.qty;
            })
        },
        mergeRowMethod ({ row, _rowIndex, column, visibleData }) {
              const fields = ['code']
              const cellValue = XEUtils.get(row, column.property)
              if (cellValue && fields.includes(column.property)) {
                const prevRow = visibleData[_rowIndex - 1]
                let nextRow = visibleData[_rowIndex + 1]
                if (prevRow && XEUtils.get(prevRow, column.property) === cellValue) {
                  return { rowspan: 0, colspan: 0 }
                } else {
                  let countRowspan = 1
                  while (nextRow && XEUtils.get(nextRow, column.property) === cellValue) {
                    nextRow = visibleData[++countRowspan + _rowIndex]
                  }
                  if (countRowspan > 1) {
                    return { rowspan: countRowspan, colspan: 1 }
                  }
                }
              }
        },
        openMatchSearch(row){
            this.express = row;
            this.matchSearchVisible = true;
        },
        openSearch(){
            this.$nextTick(() => {
                this.$refs.searchRef.init();
            });
        },
        closeSearchDialog(select,orderId){
            this.matchSearchVisible = false;
            this.checkExps.forEach(e => {
                if(e.orderId == orderId){
                    e['expStoreId'] = select.id;
                    e['expStoreCode'] = select.code;
                    e['expStoreName'] = select.productName;
                    e['expStoreSepc'] = select.productSpec;
                    e['expStoreTitle'] = select.productTitle;
                    e['expStoreQty'] = select.actualQty;
                }
            });
            this.$refs.dataTable.reloadData(this.checkExps);
        },
        //搜索库存
        searchStock(){
            let param = {
                code: this.query.code,
                name: this.query.name,
                spec: this.query.spec,
            }
            this.$axios.post(this.$kit.api.expStoreSearch, param)
                .then( (result) => {
                    if(result.status){
                        let data = result.data.list;
                        this.datas = data;
                        console.log(data);
                    }else{
                        this.$message(result.msg); 
                    }
                });
        },
        submitCheck(){
            //检查是否全部匹配完成
            let allCheck = true;
            this.checkExps.forEach(e => {
                if(!e.orderId){
                    allCheck = false;
                }
            });
            //检查库存，如果库存是0,则不与匹配
            if(!allCheck){
                this.$message(this.msg("请匹配库存"));
                return;
            }
            let param = [];
             this.checkExps.forEach(e => {
                let para = {
                    orderId: e.orderId,
                    expStoreId: e.expStoreId,
                    resendQty : e.resendQty,
                }
                param.push(para);
            });
            this.$axios.post(this.$kit.api.matchSave, param)
                .then( (result) => {
                    if(result.status){
                        this.$message(result.msg);
                        this.$emit("close-dg");
                    }else{
                        this.$message(result.msg); 
                    }
                });
        },
    }
}
</script>

<style lang="scss" scoped>
    .el-dialog__body {
    padding: 0px 20px;}
    .resend-box h2{padding-left: 15px; font-size:20px; color: rgba(100,100,100,0.9)}
    .resend-box h3{padding-left: 15px; font-size:16px; color: rgba(100,100,100,0.9)}
    .mytable-style{
        border: 1px solid rgba(0,0,0,0.7) !important;
    }
</style>