<!--物流跟踪详情-->
<template>
    <div v-if="data" class="ctrl-panel">
        <h3>{{fmt('单号：%s'+"物流跟踪详情", [data.code])}}</h3>
        <div class="trace-wrap">
            <div class="trace-box">
                <h4>{{msg('物流状态跟踪')}}</h4>
                <el-timeline :reverse="true">
                    <el-timeline-item
                        type="danger"
                        v-for="(item, index) in data.statuses" :key="index"
                        :timestamp="item.created">
                            {{item.createrName}} : {{item.ctrl}} - {{item.label}}-{{item.remark}}
                    </el-timeline-item>
                </el-timeline>
            </div>
            <div>
                <h4>{{msg("台湾物流状态跟踪")}}</h4>
                <el-timeline :reverse="true">
                    <el-timeline-item
                        type="danger"
                        v-for="(item, index) in data.twExpTracks" :key="index"
                        :timestamp="item.created">
                            {{item.remark}}
                    </el-timeline-item>
                </el-timeline>
            </div>

        </div>
        <!-- <h4>{{msg('国内物流详情')}}</h4>
        <div class="details">
            <div class="detail">
                <span>{{data.code}}</span>
                <a target="_blank" :href="'https://www.baidu.com/s?wd='+data.code">{{msg("点击查看物流")}}</a>
            </div>
            <span v-for="(item, idx) in data.details" :key="idx">
                <div class="detail" v-if="item.innerExpCode">
                    <span>{{item.innerExpCode}}({{item.shelfCode}})</span>
                    <a target="_blank" :href="'https://www.baidu.com/s?wd='+item.innerExpCode">{{msg("点击查看物流")}}</a>
                </div>
            </span>
        </div> -->
    </div>
</template>

<script>
export default {
    name: 'StockTrace',
    comments: {

    },
    mounted: function(){

    },
    props:{
      trackExpId:null,
    },
    data(){
        return{
            data: null
        }
    },
    methods: {
        showTracks(){
            this.data = null;
            this.$axios.post(this.$kit.api.traces+"?id="+this.trackExpId)
                .then((result) => {
                    if(result.status){
                        this.data = result.data;
                    }else{
                        this.$message(result.msg || this.msg('获取失败'));
                    }
                });
        }
    }
}
</script>

<style lang="scss" scoped>
.trace-wrap{display:flex; justify-content: space-between;}
.trace-box{padding: 10px;}
.detail{margin:10px;}
.detail a{display:inline-block; margin-left: 8px;}
</style>